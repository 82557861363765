










import { ActionMethod, mapActions, mapState } from 'vuex'
import { Component, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class'
import { IError } from '@/types/interfaces';

export default class ConnectPage extends Vue {
  @Action('account', { namespace: 'shopify' }) accountShopify: ActionMethod;

  login() {
    this.accountShopify(this.$route.query).then(result => {
    }).catch((err: IError) => {
      console.log(err)
    })
  }
}
